<template>
  <div
    id="card"
    class="text-center"
  >
    <v-dialog
      v-model="dialog"
      width="65%"
    >
      <v-card>
        <v-card-title class="text-h5 withe lighten-2 d-flex align-center justify-center">
          Aviso
        </v-card-title>

        <v-card-text
          v-for="(item, index) in modalContent"
          :key="index"
          class="m-0 p-0 text-h5 image-container"
        >
          <div
            v-html="cleanHTML(item.conteudo)"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="dialog = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import DOMPurify from 'dompurify';

export default {
  data() {
    return {
      dialog: false,
      modalContent: '',
    };
  },
  created() {
    this.fetchModalContent();
  },
  methods: {
    cleanHTML(dirtyHTML) {
      const clean = DOMPurify.sanitize(dirtyHTML);
      const parser = new DOMParser();
      const doc = parser.parseFromString(clean, 'text/html');
      doc.querySelectorAll('img').forEach((img) => {
        // eslint-disable-next-line no-param-reassign
        img.style.maxWidth = '100%';
        // eslint-disable-next-line no-param-reassign
        img.style.height = 'auto';
      });
      return doc.body.innerHTML;
      // return DOMPurify.sanitize(dirtyHTML);
    },
    fetchModalContent() {
      const endpoint = (`${process.env.VUE_APP_URL_SysAGA2}/api/portal/popup`);
      axios.post(endpoint, { sistema: 'portal_aga' })
        .then((response) => {
          // Verifique se o conteúdo não está vazio
          if (response.data.length > 0 && response.data[0].conteudo) {
            this.modalContent = response.data.filter((item) => item.conteudo);
            this.dialog = true;
          }
        })
        .catch((error) => {
          console.error('Erro ao buscar o conteúdo do modal:', error);
        });
    },
  },
};
</script>

<style scoped>
.image-container img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.v-dialog .v-card-text img {
  max-width: 100%;
  height: auto;
}

.v-dialog .v-card {
  max-height: 90vh;
  overflow-y: auto;
}
</style>
