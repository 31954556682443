<template>
  <div class="initial">
    <v-container
      fluid
      class="initial--section-1 d-flex flex-column white"
    >
      <v-container style="height: 100%; flex: 1">
        <v-row
          no-gutters
          style="height: 100%"
        >
          <v-col
            md="6"
            class="d-flex justify-center flex-column "
          >
            <h1 class="text-h3 font-weight-bold">
              {{ $t('inicio.bem_vindo') }}
            </h1>
            <p class="text-h6 font-weight-regular gradiente text-justify">
              {{ $t('inicio.texto') }}
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col class="text-center">
            <v-btn
              icon
              large
              style="width: 80px; height: 80px"
            >
              <v-icon
                large
                style="font-size: 6rem; color: #646E82"
                @click="$vuetify.goTo(1131)"
              >
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container
      fluid
      class="initial--section-2 pt-10 pb-10"
    >
      <h2 class="text-h3 text-center font-weight-bold">
        {{ $t('inicio.planos_projetos') }}
      </h2>
      <v-row class="pl-10 pr-10">
        <v-col
          md="4"
          class="pa-10 justify-space-around d-flex"
        >
          <v-card
            rounded="xl"
            :elevation="6"
          >
            <v-img
              src="/img/land/analise_opea.png"
              height="270px"
            />
            <v-card-title>
              <v-spacer />
              <h3>
                {{ $t('inicio.card_opea.titulo') }}
              </h3>
              <v-spacer />
            </v-card-title>
            <v-card-text class="text-center">
              {{ $t('inicio.card_opea.texto') }}
            </v-card-text>
            <v-card-actions class="pb-10">
              <v-spacer />
              <v-btn
                depressed
                dark
                color="#089BCE"
                to="/opea"
              >
                {{ $t('inicio.card_opea.botao') }}
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          md="4"
          class="pa-10 justify-space-around d-flex"
        >
          <v-card
            rounded="xl"
            :elevation="6"
          >
            <v-img
              src="/img/land/aerodromos.png"
              height="270px"
            />
            <v-card-title>
              <v-spacer />
              <h3 class="text-center">
                {{ $t('inicio.card_planos.titulo') }}
              </h3>
              <v-spacer />
            </v-card-title>
            <v-card-text class="text-center">
              {{ $t('inicio.card_planos.texto') }}
            </v-card-text>
            <v-card-actions class="pb-10">
              <v-spacer />
              <v-btn
                depressed
                dark
                color="#089BCE"
                to="/planos"
              >
                {{ $t('inicio.card_planos.botao') }}
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          md="4"
          class="pa-10 justify-space-around d-flex"
        >
          <v-card
            rounded="xl"
            :elevation="6"
          >
            <v-img
              src="/img/land/projeto_aerodromo.png"
              height="270px"
            />
            <v-card-title>
              <v-spacer />
              <h3>
                Projeto de aeródromo
              </h3>
              <v-spacer />
            </v-card-title>
            <v-card-text class="text-center">
              Para projetos de aeródromo é necessário o envio informações
              e cadastro na plataforma de acordo com o projeto que deseja enviar.<br>
              Aqui você terá acesso a todos os tipos do projeto, consultar,
              inscrever e ter acesso a planos.
            </v-card-text>
            <v-card-actions class="pb-10">
              <v-spacer />
              <v-btn
                depressed
                dark
                color="#089BCE"
                to="/aerodromo"
              >
                Acessar seção
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      fluid
      class="initial--section-3 pt-10 pb-10 white"
    >
      <v-row class="pl-10 pr-10">
        <v-col
          md="12"
        >
          <h2 class="text-h3 font-weight-bold text-center">
            Consulta ou Abertura de Processos
          </h2>
        </v-col>
        <v-col
          md="6"
          class="pa-10 justify-space-around d-flex flex-column"
        >
          <v-img
            src="/img/logos/logo-sysaga.png"
            width="203px"
            class="mb-5"
          />
          <p class="text-h6 font-weight-regular">
            O SysAGA possibilita aos usuários o preenchimento de requerimentos e
            listas de verificação de documentos, ficha informativa de aeródromos
            ou helipontos, ficha informativa de auxílios à navegação aérea,
            planilhas de elevações do perfil longitudinal, informações topográficas
            e os municípios impactados pelos planos de zona de proteção e o
            envio a um dos cinco órgãos regionais do DECEA. O sistema permite,
            ainda, a realização de consultas sobre o andamento dos processos
            sob a responsabilidade de um determinado usuário ou empresa.
          </p>
        </v-col>
        <v-col class="pa-10 justify-center align-center d-flex flex-column">
          <a
            href="https://sysaga2.decea.mil.br"
            target="_blank"
          >
            <v-btn
              dark
              depressed
              large
              color="#089BCE"
            >
              Fazer Login
            </v-btn>
          </a>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      fluid
      class="initial--section-4 pt-10 pb-10"
    >
      <v-row class="pl-10 pr-10">
        <v-col class="pa-10 justify-space-around d-flex flex-column">
          <v-card
            flat
            rounded="xl"
            class="pa-10"
          >
            <h2 class="text-h3 font-weight-bold text-center">
              Termo de Ajustamento de Conduta
            </h2>
            <br>
            <p class="text-h6 font-weight-regular">
              O Termo de Ajustamento de Conduta é um documento celebrado entre
              o operador de aeródromo ou seu representante
              designado e o DECEA, para aqueles aeródromos que foram fechados
              devido a não apresentação do Plano Básico de Zona de Proteção.
            </p>
            <p class="text-right mb-0">
              <v-btn
                dark
                depressed
                large
                color="#089BCE"
                target="_blank"
                href="/tac"
              >
                Acessar
              </v-btn>
            </p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <ModalPopup />
  </div>
</template>

<script>
import ModalPopup from '../widget/ModalPopup.vue';

export default {
  name: 'Inicio',
  components: {
    ModalPopup,
  },
  data: () => ({
    timestamp: Date.now(),
    dialog: true,
  }),
};
</script>

<style lang="scss" scoped>
    .gradiente {
            background-color: rgba(255,255,255,.8);
      padding: 10px;
    }
  .initial {
    .card {
      &-opea {
        color: #646E82;
      }
      &-legislacao {
        a {
          color: #089BCE;
        }
        small {
          color: #1c2f59;
        }
      }
    }
    &--section {
      &-1 {
        height: 831px;
        background: url('/img/land/initial_section_1_bg.png') no-repeat top right;
        background-size: contain;
      }
      &-2 {
        background-color: #F2F5F7;
      }
      &-3 {
        background: url('/img/land/initial_section_3_bg.png') no-repeat left bottom;
        background-size: contain;
      }
      &-4 {
        background-color: #F2F5F7;
      }
    }
  }
</style>
